export function getMaxDateForInput(data) {
	if (data.length === 0) {
		return "";
	}
	return Math.max(...data.map(({ date }) => date));
}

export function getMinDateForInput(data) {
	if (data.length === 0) {
		return "";
	}
	return Math.min(...data.map(({ date }) => date));
}

export function substractMonths(timestamp, months = 1) {
	const d = new Date(timestamp * 1000);
	d.setMonth(d.getMonth() - months);
	return d / 1000;
}

export function formatDate(timestamp) {
	return new Date(timestamp * 1000).toISOString().split("T")[0];
}

export function getCurrencyFormatter(locale, currency, fractionDigits = 3) {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currencyDisplay: "narrowSymbol",
		currency,
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
}

export function getPercentageFormatter(locale, fractionDigits = 3) {
	return new Intl.NumberFormat(locale, {
		style: "percent",
		minimumFractionDigits: fractionDigits,
		maximumFractionDigits: fractionDigits,
	});
}

export function getDateFormatter(locale) {
	return new Intl.DateTimeFormat(locale, {
		year: "numeric",
		month: "short",
		day: "numeric",
	});
}

export function convertCurrency(
	value,
	fromCurrency,
	toCurrency,
	currencyRates,
) {
	if (fromCurrency === toCurrency) {
		return value;
	}

	if (fromCurrency === "USD") {
		return value * currencyRates[toCurrency];
	}

	if (toCurrency === "USD") {
		return value * (1 / currencyRates[fromCurrency]);
	}

	return value * (1 / currencyRates[fromCurrency]) * currencyRates[toCurrency];
}

export function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

export function getCookie(name) {
	const cookie = document.cookie.split(";");
	for (let i = 0; i < cookie.length; i++) {
		const [key, value] = cookie[i].split("=");
		if (key.trim() === name) {
			return value;
		}
	}
	return null;
}

export function setCookie(name, value, expireSeconds = 2147483647) {
	document.cookie = `${name}=${encodeURIComponent(
		value,
	)}; max-age=${expireSeconds}; path=/; Secure; SameSite=Strict`;
}
