import { getCookie, setCookie } from "../../services/util.js";
class {
    onCreate(input) {
        this.state = {
            currency: input.currency,
            currencies: [
                {
                    code: "USD",
                    name: "$",
                },
                {
                    code: "EUR",
                    name: "€",
                },
                {
                    code: "GBP",
                    name: "£",
                },
                {
                    code: "CNY",
                    name: "¥",
                },
                {
                    code: "CAD",
                    name: "CA$",
                },
                {
                    code: "AUD",
                    name: "AU$",
                },
            ],
        };
    }

    currencySelected(el) {
        if(el.target.value !== this.state.currency) {
            this.state.currency = el.target.value;
            setCookie("currency", this.state.currency);
            location.reload();
        }
    }
}

<select#currency-select
    on-change("currencySelected")
    aria-label="Select currency"
>
    <for|option, index| of=state.currencies>
        <option
            key=option.code
            value=option.code
            selected=option.code === state.currency
        >
            ${option.name}
        </option>
    </for>
</select>

style {
    @media (max-width: 600px) {
        select#currency-select {
            order: 1;
            margin-right: auto;
        }
    }
}